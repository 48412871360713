// NAVIGATION
export const NAV_LINKS = [
    { href: '/', key: 'home', label: 'Home' },
    { href: '/services', key: 'services', label: 'Services' },
    { href: '/contact', key: 'contact_us', label: 'Contact Us' },
];

// FEATURES SECTION
export const FEATURES = [
  {
    title: 'Web Development',
    icon: '/webdevicon.svg',
    variant: 'orange',
    subtitle: "Your Digital Home, Perfected!",
    description: "Your website is more than just an online presence—it's your 24/7 salesperson, customer service representative, and brand ambassador. With our Web Development services, we'll build you a digital home that drives results.",
    included: [
      {
        title: 'Customized Solutions',
        description: 'From sleek portfolios to robust e-commerce platforms, we tailor our web development to your unique needs and goals.'
      },
      {
        title: 'Responsive Design',
        description: 'Flawless functionality across all devices ensures you never miss an opportunity to connect with your audience.'
      },
      {
        title: 'Optimized Performance',
        description: 'Speed, SEO, and user experience are at the core of our optimization efforts, helping you climb search engine ranks.'
      },
      {
        title: 'Security & Reliability',
        description: 'Trust is key online. We implement top-notch security measures to keep your site safe and customers\' data secure.'
      }
    ],
    endtitle: "Ready to launch a website that wows? Let's build your digital home for success!"
  },
  {
    title: 'Landing Pages',
    icon: '/landingpage.png',
    variant: 'green',
    subtitle: "Convert Clicks into Customers!",
    description: 'Your website is the virtual front door to your business, and a well-crafted landing page is the key to unlocking its full potential. Our Landing Page service is designed to captivate visitors from the moment they click, guiding them seamlessly towards conversion.',
    included: [
      {
        title: 'Strategic Design',
        description: 'Meticulously designed landing pages strategically optimized for conversions drive action from your visitors.'
      },
      {
        title: 'Targeted Messaging',
        description: "Tailored messaging speaks directly to your audience's needs, creating an instant connection that compels action."
      },
      {
        title: 'Conversion Optimization',
        description: 'Continuous refinement through testing and analysis ensures your landing page performs at its best.'
      },
      {
        title: 'Lead Generation Powerhouse',
        description: "Capture valuable customer information and drive inquiries, sign-ups, and sales through effective lead generation."
      }
    ],
    endtitle: "Transform clicks into loyal customers. Let's build a landing page that converts!"
  },
  {
    title: "Social Media Management",
    icon: '/socialmediamanagment.png',
    variant: 'green',
    subtitle: "Engage, Influence, Grow!",
    description:
      "In today's digital age, social media is the heartbeat of your brand's online presence. Our Social Media Management service is your secret weapon to building a loyal following, driving engagement, and expanding your reach.",
      included: [
        {
          title: "Content Strategy",
          description: "Compelling content and captivating visuals create a strategy that resonates with your audience."
        },
        {
          title: "Engagement Boost",
          description: "Active engagement fosters conversations and builds a community around your brand."
        },
        {
          title: "Data-Driven Decisions",
          description: "Analysis of key metrics refines strategies for optimal growth and engagement."
        },
        {
          title: "Brand Reputation Management",
          description: "Maintain a positive brand image through vigilant reputation management."
        }
      ],
    endtitle: "We will help you harness the power of social media for your business. Let's elevate your online presence together!"
  },
  {
    title: 'Digital Marketing Launch Blueprint',
    icon: '/digitalmarketing.png',
    variant: 'green',
    subtitle: 'Launch Your Business to New Heights with Our Digital Marketing Blueprint!',
    description: "Do you want to grow your online presence? Whether you're starting from scratch or aiming to skyrocket your current online presence, our Digital Marketing Launch Blueprint is your ultimate roadmap to success!",
    included: [
      {
        title: 'Ignite Your Online Presence',
        description: 'Craft a digital footprint that attracts attention and positions your brand for success.'
      },
      {
        title: 'Targeted Strategies for Growth',
        description: 'Laser-focused campaigns tailored to your business goals drive measurable results.'
      },
      {
        title: 'Scale with Confidence',
        description: "Optimize strategies for maximum growth as your business flourishes and expands."
      },
      {
        title: 'Unlock Hidden Potential',
        description: 'Uncover untapped markets and dominate your niche with data-driven insights.'
      },
      {
        title: 'Innovative Ideas, Real Results',
        description: 'Blend creativity with analytics to bring your brand to life and achieve success.'
      }
    ],
    endtitle: "Ready to make waves in the digital world? Let's launch your success story together!"
  },  
  {
    title: 'Paid Social Media Advertising',
    icon: '/paidads.png',
    variant: 'green',
    subtitle: "Ignite Your Brand's Reach with Paid Social Media Advertising!",
    description: "Break through the noise and captivate your audience with our Paid Social Media Advertising service. It's your ticket to unparalleled visibility and explosive growth!",
    included: [
      {
        title: 'Precision Targeting',
        description: 'Hyper-targeted ad campaigns reach the right audience with the right message at the right time.'
      },
      {
        title: 'Expert Optimization',
        description: 'Maximize ROI through expert optimization techniques and real-time analytics.'
      },
      {
        title: 'Sales-Driven Strategies',
        description: 'Drive sales and revenue through lead generation and conversion optimization strategies.'
      },
      {
        title: 'Data Analysis',
        description: 'Uncover invaluable insights through data analysis, refining campaigns for success.'
      },
      {
        title: 'Captivating Content',
        description: 'Stand out with captivating ad formats and creative content that sparks engagement.'
      },
      {
        title: 'Industry Leadership',
        description: "Position yourself as an industry leader with our paid social media advertising services."
      }
    ],
    endtitle: "We can help take your brand to new heights. Let's launch your social media success story together!"
  },
  {
    title: 'Logo & Branding Kits',
    icon: '/logokit.png',
    variant: 'orange',
    subtitle: "Stand Out, Be Remembered!",
    description: "Your brand is more than just a logo—it's the heart and soul of your business. With our Logo & Branding Kits, we'll help you create a memorable brand identity that leaves a lasting impression on your audience.",
    included: [
      {
        title: 'Customized Design',
        description: 'Expert designers craft a logo and branding elements that reflect your unique identity.'
      },
      {
        title: 'Consistent Branding',
        description: 'Ensure consistency across all branding materials for a cohesive and professional image.'
      },
      {
        title: 'Brand Storytelling',
        description: 'Engage your audience on an emotional level with captivating visuals and messaging.'
      },
      {
        title: 'Brand Recognition',
        description: 'Stand out in crowded markets and leave a mark on every customer\'s mind.'
      }
    ],
    endtitle: "Want to make your mark with a standout brand identity? Let's create a Logo & Branding Kit that sets you apart!"
  },
];

export const PRICING_CHALLENGES_SOLVED = [
  {
    challenge: "I want to grow my revenue",
  }
]
  
// FOOTER SECTION
export const FOOTER_LINKS = [
  {
    title: 'Learn More',
    links: [
      { text: 'About AG Marketing', url: 'https://www.agmarketing.com/about' },
      { text: 'Jobs', url: 'https://www.agmarketing.com/jobs' },
      { text: 'Privacy Policy', url: 'https://www.agmarketing.com/privacy-policy' },
      { text: 'Contact Us', url: 'https://www.agmarketing.com/contact' },
    ],
  },
  {
    title: 'Partners',
    links: [
      { text: 'Developing Life', url: 'https://www.developinglife.tech' },
      { text: 'Code4Solutions', url: 'https://www.code4solutions.com' },
      { text: 'JOAK', url: 'https://juanofakind.kitchen/' },
    ],
  },
];

  export const FOOTER_CONTACT_INFO = {
    title: 'Contact Us',
    links: [
      { label: 'Phone', value: '385-775-8800' },
      { label: 'Email', value: 'ag.marketingnow@gmail.com' },
    ],
  };
  
  export const SOCIALS = {
    title: 'Social',
    links: [
      '/facebook.svg',
      '/instagram.svg',
    ],
  };
   