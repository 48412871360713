import React from 'react'
import PricingComponent from '../components/PricingComponent'

const Pricing = () => {
  return (
    <>
        <PricingComponent />
    </>
  )
}

export default Pricing