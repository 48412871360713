import React from 'react'
import Landing from '../components/Landing'
import FeaturedServices from '../components/FeaturedServices'
import Calendar from '../components/Calendar'
import ContactCTA from '../components/ContactCTA'
import ClarityCallCTA from '../components/ClarityCallCTA'
import { PopupWidget } from 'react-calendly'

const Home = () => {
  return (
    <>
      <Landing />
      <ClarityCallCTA />
      <Calendar />
      <ContactCTA />
      <PopupWidget
        url="https://calendly.com/ag-marketingnow"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Schedule a call!"
        textColor="#ffffff"
        color="#000"
      />
    </>
  )
}

export default Home