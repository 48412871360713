import { InlineWidget, PopupWidget } from "react-calendly";

const Calendar = () => {
  return (
    <section className="flexCenter w-full flex-col pb-[100px] drop-in">
      <div className="get-app">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-1/2 flex flex-col">
            <div className="h-1/2 mb-2">
              <img src="marketingimg3.jpg" alt="Top Image" className="h-full w-full object-cover rounded-lg" />
            </div>
            <div className="flex h-1/2 gap-2 mr-2">
              <img src="marketingimg1.jpg" alt="Bottom Image 1" className="h-full w-1/2 object-cover rounded-lg" />
              <img src="marketingimg2.jpg" alt="Bottom Image 2" className="h-full w-1/2 object-cover rounded-lg" />
            </div>
          </div>
          <div className="lg:w-1/2 px-4">
            <h2 className="text-xl font-bold">Marketing is crucial, but your to-do list is already overflowing with a hundred and one essential tasks.</h2>
            <p>So, how can you truly leverage your marketing efforts?</p>
            <p>Do you try to do it all yourself? Sure, that works if you've got time to spare.</p>
            <p>But if you're juggling multiple responsibilities, it's just not practical.</p>
            <p>Hiring new staff? Finding the right talent is tough, not to mention the costs and dependency on one person.</p>
            <p>So, what's the smart choice?</p>
            <br />
            <p><strong>Ok, but what makes us different?</strong></p>
            <ul>
              <li><strong>Guarantee</strong> - We only win when you win. That is the basis for good cooperation. You don't have to bear all the risk.</li>
              <li><strong>Results</strong> - Our first priority is to achieve results for you as a customer. Less blah, more results.</li>
              <li><strong>Specialization</strong> - Jack of all trades... Specialization works. That is why we work with industries where we know we can achieve results.</li>
              <li><strong>Industry Specialization</strong> - We excel where we have expertise. Our focus industries ensure we deliver exceptional results tailored to your needs.</li>
            </ul>
            <p>Ready to take your marketing to the next level? 
              <br />
              <strong>Let's make it happen together.</strong></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Calendar
