import Button from "./Button"

const ClarityCallCTA = () => {
  return (
    <section className="flexCenter w-full flex-col pb-[100px] drop-in-3">
        <div className="clarity-call-cta">
        <div className="flex w-full flex-1 flex-col items-center border-solid border-2 rounded-lg shadow-lg p-4">
          <div>
            <img src="/affordablecolor.png" alt="map" width={72} height={72} />
          </div>
          <p className="text-black-30 font-bold text-lg">Affordable Services</p>
          <p>Do what you do best. We manage the marketing. Together we will take your business to the next level.</p>
        </div>
        <div className="flex w-full flex-1 flex-col items-center border-solid border-2 rounded-lg shadow-lg p-4">
          <div>
            <img src="/expertcolor.png" alt="map" width={72} height={72} />
          </div>
          <p className="text-black-30 font-bold text-lg">Industry Experts</p>
          <p>Marketing is important... but there are already 101 things on your to-do list. And they're all important too! Let us lighten your load.</p>
        </div>
        <div className="flex w-full flex-1 flex-col items-center border-solid border-2 rounded-lg shadow-lg p-4">
          <div className="">
            <img src="/automatedexeccolor.png" alt="map" width={72} height={72} />
          </div>
          <p className="text-black-30 font-bold text-lg">Guaranteed Growth</p>
          <p>We only win if you win. That's the basis for a good partnership. You won't carry all the risk, we'll share it with you.</p>
        </div>
        </div>
        {/* <a href="/contact">
          <Button
            type="button"
            title="Yes, I want that!"
            variant="btn_green"
            className="landing-button"
          />
        </a> */}
    </section>
  )
}

export default ClarityCallCTA