import React from 'react'
import Button from './Button'

const ContactCTA = () => {
  return (
    <section className="flexCenter w-full flex-col pb-[100px]">
      <div className="clarity-call-cta">
        <div className="z-20 flex w-full flex-1 flex-col items-start justify-center gap-12">
          <h2 className="bold-40 lg:bold-64 xl:max-w-[320px]">Let Us Help</h2>
          <p className="regular-16">Start getting your business the results you dream of.</p>
          <div className="flex w-full flex-col gap-3 whitespace-nowrap xl:flex-row">
            <a href="/contact">
            <Button 
              type="button"
              title="Contact Us"
              variant="btn_white"
              full
            />
            </a>
            <a href="https://calendly.com/ag-marketingnow">
            <Button 
              type="button"
              title="Schedule a Call"
              variant="btn_dark_green_outline"
              full
            />
            </a>
          </div>
        </div>

        <div className="flex flex-1 items-center justify-end">
          <img src="/cta.jpg" alt="marketing" width={550} height={870} />
        </div>
      </div>
    </section>
  )
}

export default ContactCTA