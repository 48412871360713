import { useState, useEffect, useRef } from 'react';
import { NAV_LINKS } from "../utils/constants";
import Button from "./Button";
import { FiAirplay } from 'react-icons/fi';
import { AiOutlineMail } from "react-icons/ai";
import { LuPhone } from "react-icons/lu";

const Navbar = () => {
  const [sidebarVisible, setSidebarVisible] = useState(false);
  const sidebarRef = useRef(null);
  const menuButtonRef = useRef(null);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        sidebarRef.current && 
        !sidebarRef.current.contains(event.target) &&
        menuButtonRef.current && 
        !menuButtonRef.current.contains(event.target)
      ) {
        // Click is outside of the sidebar and menu button, close the sidebar
        setSidebarVisible(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <nav className="flexBetween max-container padding-container relative z-30 py-5">
      <a href="/">
        <img src="agmnow.png" alt="logo" width={80} height={34}/>
      </a>
      <ul className={`h-full gap-12 lg:flex ${sidebarVisible ? 'block' : 'hidden'}`}>
        {NAV_LINKS.map((link) => (
          <a href={link.href} key={link.key} className="regular-16 text-gray-50 flexCenter cursor-pointer pb-1.5 transition-all hover:font-bold">
            {link.label}
          </a>
        ))}
      </ul>
      <div className="lg:flexCenter hidden">
        <a href="/contact">
          <Button 
            type="button"
            title="Contact Us"
            variant="btn_dark_green"
          />
        </a>
      </div>

      <img
        ref={menuButtonRef}
        src={sidebarVisible ? "closeicon.svg" : "menu.svg"}
        alt={sidebarVisible ? "close" : "menu"}
        width={32}
        height={32}
        className="inline-block cursor-pointer lg:hidden z-10"
        onClick={toggleSidebar}
      />

      {/* Sidebar */}
      <div ref={sidebarRef} className={`lg:hidden fixed top-0 right-0 h-full w-64 bg-green-90 items-center ${sidebarVisible ? 'block' : 'hidden'}`}>
        {/* Add padding to the top */}
        <div className="pt-20">
        {NAV_LINKS.map((link) => (
          <a href={link.href} key={link.key} className="regular-24 text-tan-50 flexCenter cursor-pointer pb-1.5 transition-all hover:font-bold">
            {link.label}
          </a>
        ))}
        <div className="pt-36">
          
          <p className="flexCenter text-tan-50"><LuPhone /> 385-775-8800</p>
          <p className="flexCenter text-tan-50"><AiOutlineMail /> ag.marketingnow@gmail.com</p>
        </div>
        {/* <div className="px-4">
        <a href="/contact" className="">
          <Button 
            type="button"
            title="Contact Us"
            variant="btn_dark_green"
          />
        </a>
        </div> */}
        </div>
      </div>
    </nav>
  )
}

export default Navbar;
