import React from 'react'
import Button from './Button'
import { PopupWidget } from 'react-calendly'

const Landing = () => {
  return (
    <section className="max-container padding-container flex flex-col gap-20 py-10 pb-32 md:gap-28 lg:py-20 xl:flex-row text-center drop-in-2">
      <div className="hero-map" />

      <div className="relative z-20 flex flex-1 flex-col xl:w-1/2 items-center">
        <h1 className="bold-52 lg:bold-88">Digital Marketing Agency</h1>
        <p className="regular-24 mt-6 text-green-30 xl:max-w-[520px] text-center">
        More Growth. More Customers. Guaranteed.
        </p>

        <div className="flex flex-col gap-3 sm:flex-row py-2">
        <a href="/contact">
          <Button
            type="button"
            title="Yes, I'm ready!"
            variant="btn_green"
          />
        </a>
        </div>
      </div>

    </section>
  )
}

export default Landing