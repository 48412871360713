import axios from 'axios';
import React, { useState, useNavigate } from 'react'
import { useForm } from "react-hook-form";
import { FiMail } from "react-icons/fi";

const ContactForm = () => {
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = data => console.log(data);
  console.log(errors);

  const [fullName, setFullName] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')
  const [request, setRequest] = useState('')
  const [success, setSuccess] = useState(false); // State to manage success state


  let PROJECT_ID = "37b0dqcv"
  let DATASET = "production"
  let URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/mutate/${DATASET}`;

  const token = "skan0d2buKLChfKpwy1uzDKAZhlo1NmTfPtKI3wefLdEw2XlbFJeu43jB8pnMnTgr6DcfIjyDsMlDWMoiLOxPF09dq5rkXj8x5Bn7qM26Zjam4DOXws2hX09jZ0iDvFHBixxYUHeoABcgGn6C7t6pfu4lBdqgnl3YAxIYAbC52n0pZgbSskO"

  const submitContactRequest = async (e) => {
    e.preventDefault();
    const mutations = [{
      createOrReplace: {
        _type: 'contact',
        fullname: `${fullName}`,
        phone: `${phone}`,
        email: `${email}`,
        message: `${request}`,
      }
    }]

    try {
      const response = await axios.post(URL, { mutations }, {
        headers: {
          'Content-type': 'application/json',
          'Authorization': `Bearer ${token}`
        }
      });
  
      console.log(response.data);
      console.log(mutations)
      console.log("This worked!")
      alert("Your contact info has been submitted!")
    } catch (error) {
      console.error(error);
      console.log("This didn't work!")
      alert("Your contact request was not submitted. The developer has been informed.")
    }
  }


  return (
    <>
        <div className="container flex flex-col md:flex-row lg:max-w-5xl w-full px-5 py-12 md:py-24 mx-auto section drop-in-3">
        <div className="md:w-1/3 w-full">
            <h1 className="text-4xl text-gray-800 sm:text-4xl font-bold title-font mb-4">Contact Us</h1>
            <p className="leading-relaxed text-xl text-black">
                We're here to assist you! If you have any questions or need assistance, please feel free to reach out to us. You can schedule a call using the button below.
                <br/>
                <br />
                You can also email us at:
                <a href="mailto: ag.marketingnow.com"
                    className="font-semibold border-b-4 border-green-90"> ag.marketingnow.com</a>
            </p>
        </div>
        <div className="md:w-2/3 w-full mt-10 md:mt-0 md:pl-28">
        <h1 className="text-4xl text-gray-800 sm:text-4xl font-bold title-font mb-4">Contact Form</h1>
        <form onSubmit={submitContactRequest}>
                <div className="p-2 w-full">
                    <div className="relative">
                        <label htmlFor="name" className="leading-7 py-4 text-lg text-black">Your Name</label>
                        <input type="text" id="name" name="name" required=""
                            className="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-black py-1 px-1 leading-8 transition-colors duration-200 ease-in-out" {...register("Full name", {required: true, maxLength: 80})} onChange={(e) => setFullName(e.target.value)}></input>
                    </div>
                </div>
                <div className="p-2 w-full">
                    <div className="relative">
                        <label htmlFor="phone" className="leading-7 py-4 text-lg text-black">Phone Number</label>
                        <input type="phone" id="phone" name="phone" required=""
                            className="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-black py-1 px-1 leading-8 transition-colors duration-200 ease-in-out" {...register("Phone Number", {required: true, pattern: /^\S+@\S+$/i})} onChange={(e) => setPhone(e.target.value)}></input>
                    </div>
                </div>
                <div className="p-2 w-full">
                    <div className="relative">
                        <label htmlFor="email" className="leading-7 py-4 text-lg text-black">Email</label>
                        <input type="email" id="email" name="email" required=""
                            className="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-black py-1 px-1 leading-8 transition-colors duration-200 ease-in-out" {...register("Email", {required: true, pattern: /^\S+@\S+$/i})} onChange={(e) => setEmail(e.target.value)}></input>
                    </div>
                </div>
                <div className="p-2 w-full">
                    <div className="relative">
                        <label htmlFor="message" className="leading-7 py-4 text-lg text-black">What is your most important question?</label>
                        <textarea id="message" name="message" required=""
                            className="w-full bg-white rounded border border-gray-400 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-black py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out" {...register("Request", {required: true})} onChange={(e) => setRequest(e.target.value)}></textarea>
                    </div>
                </div>
                <div className="p-2 w-full">
                    <button type="submit" onSubmit={submitContactRequest}
                        className="flex text-white bg-green-90 border-0 py-4 px-6 focus:outline-none hover:bg-black rounded text-xl font-bold shadow-lg mx-0 flex-col text-center g-recaptcha">
                        Send Message ✉
                    </button>
                </div>
        </form>
        </div>  
        </div>
    </>
  )
}

export default ContactForm