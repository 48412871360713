import React from 'react'
import ContactForm from '../components/ContactForm'

export const Contact = () => {
  return (
    <div className="">
        <ContactForm />
    </div>
  )
}
