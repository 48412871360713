import { useState } from 'react'
import { Route, Routes } from "react-router-dom"
import Navbar from './components/Navbar'
import Footer from './components/Footer'
import Home from './pages/Home'
import Login from './pages/Login'
import { Contact } from './pages/Contact'
import Services from './pages/Services'
import Pricing from './pages/Pricing'

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="login" element={<Login />} />
      </Routes>
      <Footer />
    </>
  )
}

export default App
