import React from 'react'
import FeaturedServices from '../components/FeaturedServices'
import AllServices from '../components/AllServices'

const Services = () => {
  return (
    <div>
      <AllServices />
    </div>
  )
}

export default Services