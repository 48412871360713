import React from 'react';
import { IoCheckmarkCircleOutline } from "react-icons/io5";

const PricingComponent = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-3xl font-bold text-center mb-8">Pricing</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        <div className="clarity-call-cta pricing-item">
          <div className="">
            <h2 className="text-xl font-bold mb-4">Types of challenges we help solve...</h2>
            <ul className="pl-6 list-disc">
              <li>I want to grow my revenue</li>
              <li>I need better business management tools</li>
              <li>I don’t have time to focus on marketing</li>
              <li>I want a marketing strategy that works for my industry</li>
              <li>I need a modern website or online store</li>
              <li>I want more qualified leads</li>
              <li>I need a system to manage my leads and customers</li>
              <li>I need a better online reputation and social presence</li>
              <li>I need help with email marketing</li>
              <li>I need help with branding, design, video, content</li>
              <li>And many more — Your success is our priority!</li>
            </ul>
          </div>
        </div>

        <div className="clarity-call-cta pricing-item">
          <div className="">
            <h2 className="text-xl font-bold mb-4">Industry Specialists</h2>
            <p className="mb-4">
              Empower your company with the expertise of an AGM CRO to craft a comprehensive marketing strategy, steering clear of decisions by inexperienced marketers.
            </p>
            <p className="mb-4">
              Automated Executive: An AGM CMO, overseeing all aspects of your company's marketing needs, will lighten your workload. This executive role extends to managing relations with suppliers and staff.
            </p>
            <p>
              Affordable Cost: We will help you boost your revenue and provide guidance without the cost of a full-time CMO or marketing agency.
            </p>
          </div>
        </div>

        <div className="clarity-call-cta pricing-item">
          <div className="">
            <h2 className="text-xl font-bold mb-4">Unlock Rapid Growth</h2>
            <p>
              WITH AN AGM FRACTIONAL CMO: Elevate your business to new heights with our high-speed growth blueprint. Partner with AGM CMO to access expert strategy, guidance, and management across all aspects of your marketing operations.
            </p>
            <p className="mt-4">
              Lack Of Time Or Know-How Will Ruin Your Marketing Effort. AG Marketing Solution: Don't Let Time or Expertise Hold Back Your Marketing Success. There's no excuse when your idea has been tested, attracted investors, and secured capital.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center mt-8">
        <p className="mb-4">Are you ready to rejuvenate your business with the support of a trusted fractional CMO?</p>
            <a href="https://calendly.com/ag-marketingnow"><button className="bg-green-90 hover:bg-green-90 h-16 text-white px-6 py-3 rounded-lg shadow-md transition duration-300">Book a Consultation</button></a>
      </div>
    </div>
  );
};

export default PricingComponent;
