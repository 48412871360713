import { FEATURES } from '../utils/constants'
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import Button from './Button';


const AllServices = () => {
  return (
    <section className="flex-col flexCenter overflow-hidden bg-feature-bg bg-center bg-no-repeat py-24">
      <div className="max-container padding-container relative w-full flex justify-end">
        <ul className="mt-10 grid gap-10 md:grid-cols-2 lg:mg-20 lg:gap-20">
          {FEATURES.map((feature, index) => (
            <FeatureItem 
              key={index} 
              title={feature.title} 
              icon={feature.icon}
              description={feature.description}
              subtitle={feature.subtitle}
              included={feature.included}
              endtitle={feature.endtitle}
              className="service-item"
            />
          ))}
        </ul>
      </div>
    </section>
  )
}

const FeatureItem = ({ title, icon, description, included, subtitle, endtitle }) => {
    return (
      <li className="flex w-full flex-1 flex-col items-center border-solid border-2 rounded-lg shadow-lg p-4 service-item">
        <div className="rounded-full p-4 lg:p-7 bg-green-50">
          <img src={icon} alt="map" width={28} height={28} />
        </div>
        <h2 className="bold-20 lg:bold-32 mt-5 capitalize">
          {title}
        </h2>
        <h3 className="bold-10 lg:bold-16 mt-3 capitalize">
          {subtitle}
        </h3>
        <div className="flex flex-col gap-3 sm:flex-row py-2">
        <a href="/contact">
          <Button
            type="button"
            title="Learn More"
            variant="btn_green"
          />
        </a>
        </div>
      </li>
    )
  }
  

export default AllServices